<template>
  <div>
    <b-form-row>
      <b-col cols="3">
        <b-form-group
          label="公司"
        >
          <b-form-select
            v-model="form.company_id"
            :options="resources.companys"
            value-field="id"
            text-field="name"
            @change="changeCompany"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-button
          variant="outline-primary"
          @click="showModal('新增')"
          style="margin-top: 31px;"
        >新增</b-button>
      </b-col>
    </b-form-row>
    
    <b-table
      :items="resources.caseScenes"
      :fields="fields"
      striped
      hover
      small
      responsive
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).format('Y-m-d H:i') }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.item.updated_at ? new Date(data.item.updated_at).format('Y-m-d H:i') : '' }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mr-2"
          @click="showModal('編輯', data.item)"
          >編輯</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          @click="deleteCaseScene(data.item)"
          >刪除</b-button
        >
      </template>
    </b-table>

    <b-modal
      size="lg"
      ref="modalRef"
      :title="modalTitle"
      ok-title="儲存"
      cancel-title="取消"
      :no-close-on-backdrop="true"
      :no-close-on-esc="false"      
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col cols="4">
            <b-form-group
              label="名稱"
              invalid-feedback="必填"
              :state="state.name"
              class="fm-required"
            >
              <b-form-input
                v-model="form.name"
                type="text"
                autocomplete="off"
                required
                @input="checkState('name')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="國家"
              invalid-feedback="必填"
              :state="state.country"
              class="fm-required"
            >
              <b-form-select
                v-model="form.country"
                :options="resources.countrys"
                value-field="value"
                text-field="text"
                required
                @input="checkState('country')"
              >
                <template #first>
                  <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="縣市"
              invalid-feedback="必填"
              :state="state.city"
              class="fm-required"
            >
              <b-form-select
                v-model="form.city"
                :options="resources.citys"
                value-field="value"
                text-field="text"
                required
                @input="checkState('city')"
              >
                <template #first>
                  <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="地址">
              <b-form-input
                v-model="form.address"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="經度"
              invalid-feedback="必填"
              :state="state.longitude"
              class="fm-required"
            >
              <b-form-input
                v-model.number="form.longitude"
                type="number"
                step="0.000000000000001"
                autocomplete="off"
                required
                @input="checkState('longitude')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="緯度"
              :state="state.latitude"
              invalid-feedback="必填"
              class="fm-required"
            >
              <b-form-input
                v-model.number="form.latitude"
                type="number"
                step="0.000000000000001"
                autocomplete="off"
                required
                @input="checkState('latitude')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="併網日期"
              invalid-feedback="必選"
              :state="state.merge_date"
              class="fm-required"
            >
              <datepicker
                v-model="form.merge_date"
                :value="form.merge_date"
                :language="zh"
                :bootstrap-styling="true"
                format="yyyy-MM-dd"
                @input="checkState('merge_date')"
              ></datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="裝置容量"
              invalid-feedback="必填"
              :state="state.device_capacity"
              class="fm-required"
            >
              <b-form-input
                v-model.number="form.device_capacity"
                type="number"
                step="0.001"
                autocomplete="off"
                required
                @input="checkState('device_capacity')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="容量單位"
              invalid-feedback="必選"
              :state="state.capacity_id"
              class="fm-required"
            >
              <b-form-select
                v-model="form.capacity_id"
                :options="resources.capacitys"
                value-field="id"
                text-field="unit"
                required
                @input="checkState('capacity_id')"
              >
                <template #first>
                  <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="模組廠牌">
              <b-form-input
                v-model="form.module_brand"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="模組型號">
              <b-form-input
                v-model="form.module_model"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="模組片數"
              invalid-feedback="必填"
              :state="state.module_total"
              class="fm-required"
            >
              <b-form-input
                v-model.number="form.module_total"
                type="number"
                step="1"
                autocomplete="off"
                required
                @input="checkState('module_total')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="展示">
              <b-form-checkbox
                v-model="form.is_demo"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="有檢測">
              <b-form-checkbox
                v-model="form.has_detection"
                :value="1"
                :unchecked-value="0"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="備註">
              <b-form-input
                v-model="form.note"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { zh } from 'vuejs-datepicker/dist/locale'   // 設定語系

export default {
  components: {
    Datepicker
  },
  data () {
    return {
      zh: zh,
      modalTitle: null,
      caseSceneID: null,
      state: {
        name: null,
        country: null,
        city: null,
        longitude: null,
        latitude: null,
        merge_date: null,
        device_capacity: null,
        capacity_id: null,
        module_total: null
      },
      form: {
        name: null,
        country: null,
        city: null,
        address: null,
        longitude: null,
        latitude: null,
        merge_date: new Date(),
        device_capacity: null,
        capacity_id: null,
        module_brand: null,
        module_model: null,
        module_total: null,
        is_demo: 0,
        has_detection: 0,
        company_id: null,
        note: null,
      },
      resources: {
        caseScenes: [],
        companys: [],
        citys: [],
        capacitys: []
      },
      fields: [
        { key: 'index', label: '#' },
        { key: 'id', label: 'ID' },
        { key: 'name', label: '案場名稱', sortable: true },
        { key: 'country', label: '國家', sortable: true },
        { key: 'city', label: '縣市', sortable: true },
        { key: 'address', label: '地址', sortable: true },
        { key: 'longitude', label: '經度', sortable: true },
        { key: 'latitude', label: '緯度', sortable: true },
        { key: 'merge_date', label: '併網日期', sortable: true },
        { key: 'device_capacity', label: '裝置容量', sortable: true },
        { key: 'capacity_unit', label: '容量單位', sortable: true },
        { key: 'module_brand', label: '模組廠牌', sortable: true },
        { key: 'module_model', label: '模組型號', sortable: true },
        { key: 'module_total', label: '模組片數', sortable: true },
        { key: "is_demo", label: "展示", sortable: true },
        { key: "has_detection", label: "有檢測", sortable: true },
        { key: 'note', label: '備註', sortable: true },
        { key: 'created_at', label: '產生時間', sortable: true },
        { key: 'updated_at', label: '更新時間', sortable: true },
        { key: 'functions', label: '功能' },
      ],
    }
  },
  methods: {
    deleteCaseScene(item) {
      if (confirm("確定刪除！")) {
        this.axios
          .delete(`/case-scene/${item.id}`)
          .then(() => {
            const index = this.resources.caseScenes.findIndex(
              (element) => element.id == item.id
            );
            this.resources.caseScenes.splice(index, 1);

            this.makeToast("系統訊息", "success", "刪除成功！");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkState (name) {
      this.state[name] = this.form[name] ? true : false;
    },
    showModal (type, row) {
      this.$refs.modalRef.show();

      if (type == '新增') {
        this.modalTitle = type;
      // 編輯
      } else {
        this.modalTitle = `${type} - ID ${row.id}`;
        this.caseSceneID = row.id;

        console.log(row);

        for (let prop in this.form) {
          if ((prop != 'company_id' || prop != 'merge_date')) {
            this.form[prop] = row[prop];
          }
        }
      }
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity();
      
      for (let prop in this.state) {
        this.state[prop] = this.form[prop] ? true : false;
      }

      return valid;
    },
    resetModal () {
      /**
       * 回復預設值
       */
      for (let prop in this.form) {
        if (prop != 'company_id') {
          if (prop === 'merge_date') {
            this.form[prop] = new Date();
          } else {
            if (prop === 'is_demo' || prop === 'has_detection') {
              this.form[prop] = 0;
            } else {
              this.form[prop] = null;
            }
          }
        }
      }

      for (let prop in this.state) {
        this.state[prop] = null;
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      if (this.modalTitle == '新增') {
        this.form.merge_date = new Date(this.form.merge_date).format('Y-m-d H:i:s')
        this.axios
          .post('/case-scene', this.form)
          .then(response => {
            response.data.data.merge_date = new Date(response.data.data.merge_date).format('Y-m-d');
            this.resources.caseScenes.push(response.data.data);

            this.makeToast('系統訊息', 'success', '新增成功！');
          })
          .catch(error => {
            console.log(error);
          });
      // 編輯
      } else {
        this.form.note = this.form.note === '' ? null : this.form.note;
        this.form.merge_date = new Date(this.form.merge_date).format('Y-m-d H:i:s')

        this.axios.patch('/case-scene/' + this.caseSceneID, this.form)
          .then(response => {
            let index = this.resources.caseScenes.findIndex(element => element.id == response.data.data.id);
            
            this.resources.caseScenes.splice(index, 1, response.data.data);
            this.makeToast('系統訊息', 'success', '資料已更新！');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // Push the name to submitted names
      // this.submit.push(this.name)
      // Hide the modal manually
      this.$nextTick (() => {
        this.$refs.modalRef.hide()
      });
    },
    getCaseScene () {
      this.axios
        .get('/case-scene', { params: {
          filter: `case_scene.company_id:${this.form.company_id}`
        }})
        .then(response => {
          this.resources.caseScenes = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeCompany () {
      this.getCaseScene();
    }
  },
  created () {
    this.axios
      .get("/company")
      .then((response) => {
        this.resources.companys = response.data.data;
        this.form.company_id = this.resources.companys[0].id;

        this.getCaseScene();
      })
      .catch((error) => {
        console.log(error);
      });

    this.axios
      .get('/city')
      .then(response => {
        this.resources.citys = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

    this.axios
      .get('/country')
      .then(response => {
        this.resources.countrys = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });

    this.axios
      .get('/capacity')
      .then(response => {
        this.resources.capacitys = response.data.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
</script>

<style scoped>
.table >>> thead th {
  position: sticky !important;
  top: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  border-bottom: 0px;
}
.table-responsive >>> .table thead th,
.table-responsive >>> .table tbody td {
  font-size: 0.9rem;
  vertical-align: middle !important;
  /* 防止資料折行 */
  word-break: keep-all;
  white-space:nowrap;
}

/*
 * 必填欄位加 *
 */
.fm-required >>> legend::after {
  color: red;
  content: " *";
}
</style>
