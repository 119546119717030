<template>
  <div>
    <b-button
      class="mb-3"
      variant="outline-primary"
      @click="showModal('新增')"
    >新增</b-button>

    <b-table
      :items="resources.users"
      :fields="fields"
      striped
      hover
      small
      responsive
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(admin)="data">
        {{ data.item.admin ? "Y" : "" }}
      </template>
      <template #cell(created_at)="data">
        {{ new Date(data.item.created_at).format('Y-m-d H:i') }}
      </template>
      <template #cell(updated_at)="data">
        {{ data.item.updated_at ? new Date(data.item.updated_at).format('Y-m-d H:i') : '' }}
      </template>
      <template #cell(functions)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          class="mr-2"
          @click="showModal('編輯', data.item)"
          >編輯</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          @click="deleteUser(data.item)"
          >刪除</b-button
        >
      </template>
    </b-table>

    <b-modal
      size="lg"
      ref="modalRef"
      :title="modalTitle"
      ok-title="儲存"
      cancel-title="取消"
      :no-close-on-backdrop="true"
      :no-close-on-esc="false"      
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-row>
          <b-col cols="4">
            <b-form-group
              label="姓名"
              invalid-feedback="必填"
              :state="state.name"
              class="fm-required"
            >
              <b-form-input
                v-model="form.name"
                type="text"
                autocomplete="off"
                @input="checkState('name')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="暱稱">
              <b-form-input
                v-model="form.alias"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Email"
              invalid-feedback="必填"
              :state="state.email"
              class="fm-required"
            >
              <b-form-input
                v-model="form.email"
                type="email"
                autocomplete="off"
                @input="checkState('email')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="!/編輯/.test(modalTitle)" cols="4">
            <b-form-group
              label="密碼"
              invalid-feedback="必填"
              :state="state.password"
              class="fm-required"
            >
              <b-form-input
                v-model="form.password"
                type="text"
                autocomplete="off"
                @input="checkState('password')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="/編輯/.test(modalTitle)" cols="4">
            <b-form-group
              label="修改密碼"
            >
              <b-form-input
                v-model="form.password"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="手機">
              <b-form-input
                v-model="form.phone"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="職稱">
              <b-form-input
                v-model="form.job_title"
                type="text"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="管理者">
              <b-form-checkbox
                v-model="form.admin"
                :value="1"
                :unchecked-value="null"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="公司"
              invalid-feedback="必選"
              :state="state.company_id"
              class="fm-required"
            >
              <b-form-select
                v-model="form.company_id"
                :options="resources.companys"
                value-field="id"
                text-field="name"
                @input="checkState('company_id')"
                required
              >
                <template #first>
                  <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="身份"
              invalid-feedback="必選"
              :state="state.identity_id"
              class="fm-required"
            >
              <b-form-select
                v-model="form.identity_id"
                :options="resources.identitys"
                value-field="id"
                text-field="name"
                @input="checkState('identity_id')"
                required
              >
                <template #first>
                  <b-form-select-option :value="null">-- 請選擇 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalTitle: null,
      userID: null,
      state: {
        name: null,
        email: null,
        password: null,
        company_id: null,
        identity_id: null,
      },
      form: {
        name: null,
        alias: null,
        email: null,
        password: null,
        phone: null,
        job_title: null,
        admin: null,
        company_id: null,
        identity_id: null,
      },
      resources: {
        users: [],
        companys: [],
        identitys: [],
      },
      fields: [
        { key: "index", label: "#" },
        { key: "id", label: "ID" },
        { key: "name", label: "姓名", sortable: true },
        { key: "alias", label: "暱稱", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "phone", label: "手機", sortable: true },
        { key: "job_title", label: "職稱", sortable: true },
        { key: "admin", label: "管理者", sortable: true },
        { key: "company_name", label: "公司", sortable: true },
        { key: "identity_name", label: "身份", sortable: true },
        { key: "created_at", label: "產生時間", sortable: true },
        { key: "updated_at", label: "更新時間", sortable: true },
        { key: "functions", label: "功能" },
      ],
    };
  },
  methods: {
    deleteUser(item) {
      if (confirm("確定刪除！")) {
        this.axios
          .delete(`/user/${item.id}`)
          .then(() => {
            const index = this.resources.users.findIndex(
              (element) => element.id == item.id
            );
            this.resources.users.splice(index, 1);

            this.makeToast("系統訊息", "success", "刪除成功！");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkState (name) {
      this.state[name] = this.form[name] ? true : false;
    },
    showModal (type, row) {
      this.$refs.modalRef.show();

      if (type == '新增') {
        this.modalTitle = type;
      // 編輯
      } else {
        this.modalTitle = `${type} - ID ${row.id}`;
        this.userID = row.id;

        for (let prop in this.form) {
          if (prop === 'password') {
            this.form[prop] = null;
          } else {
            this.form[prop] = row[prop];
          }
        }
      }
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity();
      
      for (let prop in this.state) {
        this.state[prop] = this.form[prop] ? true : false;
      }

      return valid;
    },
    resetModal () {
      /**
       * 回復預設值
       */
      for (let prop in this.form) {
        this.form[prop] = null
      }

      for (let prop in this.state) {
        this.state[prop] = null
      }
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      if (this.modalTitle == '新增') {
        this.axios
          .post('/user', this.form)
          .then(response => {
            this.resources.users.push(response.data.data[0]);

            this.makeToast('系統訊息', 'success', '新增成功！');
          })
          .catch(error => {
            console.log(error);
          });
      // 編輯
      } else {
        let request = Object.assign({}, this.form);

        if (!request.password) {
          delete request.password;
        }

        this.axios.patch('/user/' + this.userID, request)
          .then(response => {
            let index = this.resources.users.findIndex(element => element.id == response.data.data[0].id);
            
            this.resources.users.splice(index, 1, response.data.data[0]);
            this.makeToast('系統訊息', 'success', '資料已更新！');
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // Push the name to submitted names
      // this.submit.push(this.name)
      // Hide the modal manually
      this.$nextTick (() => {
        this.$refs.modalRef.hide()
      });
    },
  },
  created() {
    this.axios
      .get("/user")
      .then((response) => {
        this.resources.users = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.axios
      .get("/company")
      .then((response) => {
        this.resources.companys = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });

    this.axios
      .get("/identity")
      .then((response) => {
        this.resources.identitys = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.table >>> thead th {
  position: sticky !important;
  top: 0;
  text-align: center;
  z-index: 2;
  background-color: white;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  border-bottom: 0px;
}
.table-responsive >>> .table thead th,
.table-responsive >>> .table tbody td {
  font-size: 0.9rem;
  vertical-align: middle !important;
  /* 防止資料折行 */
  word-break: keep-all;
  white-space:nowrap;
}

/*
 * 必填欄位加 *
 */
.fm-required >>> legend::after {
  color: red;
  content: " *";
}
</style>
