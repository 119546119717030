<template>
  <div id="app" class="d-flex flex-column" :class="{ 'vh-100': $route.path === '/login' }">
    <div class="d-flex flex-column justify-content-center flex-grow-1">
      <b-container v-if="$route.path != '/report'" fluid class="mb-2 mt-3">
        <b-row v-if="$route.path != '/login'" class="py-2 px-2">
          <b-col>
            <header class="mt-2 mb-3">
              <h1>艾瑞遙航科技有限公司</h1>
              <h2>{{ $store.state.user.company_name }}</h2>
            </header>
          </b-col>
          <b-col class="text-right">
            <span style="vertical-align: middle;">{{ $store.state.user.name }}</span>
            <b-button
              variant="link"
              @click="logout()"
            >登出</b-button>
          </b-col>
        </b-row>
      </b-container>

      <b-navbar v-if="$route.path != '/login' && $route.path != '/report'" toggleable="xl" type="dark" variant="dark" class="mb-3">
        <b-navbar-toggle target="fm-nav-collapse"></b-navbar-toggle>

        <b-collapse id="fm-nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/admin/company" :active="$route.path == '/admin/company'">公司</b-nav-item>
            <b-nav-item to="/admin/user" :active="$route.path == '/admin/user'">使用者</b-nav-item>
            <b-nav-item to="/admin/case-scene" :active="$route.path == '/admin/case-scene'">案場</b-nav-item>
            <b-nav-item to="/admin/detection" :active="$route.path == '/admin/detection'">檢測</b-nav-item>
            <b-nav-item to="/admin/thermal-defect" :active="$route.path == '/admin/thermal-defect'">熱缺陷</b-nav-item>
            <b-nav-item to="/admin/identity" :active="$route.path == '/admin/identity'">身份</b-nav-item>
            <b-nav-item to="/admin/capacity" :active="$route.path == '/admin/capacity'">裝置容量</b-nav-item>
            <b-nav-item to="/admin/weather" :active="$route.path == '/admin/weather'">天氣狀況</b-nav-item>
            <b-nav-item to="/admin/clean" :active="$route.path == '/admin/clean'">清潔狀況</b-nav-item>
            <b-nav-item to="/admin/thermal-camera-pixel" :active="$route.path == '/admin/thermal-camera-pixel'">熱像儀像素</b-nav-item>
            <b-nav-item to="/admin/thermal-defect-category" :active="$route.path == '/admin/thermal-defect-category'">熱缺陷種類</b-nav-item>
          </b-navbar-nav>

          <!-- Taiwan Map dropdown -->
          <b-navbar-nav class="ml-auto">
            <b-nav-form>
              <b-form-select
                v-model="adminCompanyId"
                :options="resources.companies"
                value-field="id"
                text-field="name"
                @change="checkCompany"
              >
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="null" disabled>-- 請選擇【台灣地圖】 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-nav-form>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <b-container fluid class="mb-3">
        <b-row>
          <b-col>
            
            <router-view/>

          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adminCompanyId: null,
      resources: {
        companies: [],
      }
    };
  },
  methods: {
    logout () {
      const config = {
        headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
      };

      this.axios
        .get('/logout', config)
        .then((response) => {
          if (response.status) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            this.$store.commit('setToken', null);
            this.$store.commit('setUser', {});
            
            this.$router.push({ path: '/login' });
          }
        })
        .catch(error => {
          console.log(error);

          // localStorage.removeItem('token');
          // localStorage.removeItem('user');
          
          // this.$router.push({ path: '/login' });

          // this.makeToast('系統訊息', 'danger', '此帳號其他人已登出！');
        });
    },

    /**
     * Get all the companies
     */
    getCompanies() {
      this.axios.get('/company')
        .then(response => {
          this.resources.companies = response.data.data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },

    checkCompany() {
      let userData = localStorage.getItem('user');
      
      if (userData) {
        let user = JSON.parse(userData);
        user.company_id = parseInt(this.adminCompanyId); 
        let updatedUserData = JSON.stringify(user);
        localStorage.setItem('user', updatedUserData);
      }
      this.$router.push('/taiwan-map');
    }
  },
  mounted() {
    if (!this.resources.companies.length && this.$route.path.includes("admin/")) {
      this.getCompanies();
    }
  },
  updated() {
    if (!this.resources.companies.length) {
      this.getCompanies();
    }
  }
}
</script>

<style scoped>
header img {
  height: 65px;
  width: auto;
}
header h1 {
  font-size: 1.65rem;
}
header h2 {
  font-size: 1rem;
  font-weight: bold;
}
</style>
